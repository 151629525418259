import React, { Component } from 'react';

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {}

  render() {
    const { title, image, style } = this.props;
    return (
      <div className="work">
        <div
            className="work-image"
            style={{ ...style, backgroundImage: `url(${image})` }}
        >
        </div>
        <div className="work-title">
            {title}
        </div>

      </div>
    )
  }
}
