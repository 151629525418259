import React, { Component } from 'react'
import bulb from './images/bulb.jpg';
import ea from './images/ea.jpg';
import edtech from './images/edtech.jpg';
import speedo from './images/speedo.jpg';
import logo from './images/logo.png';
import Work from './Work';

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * Component Did Mount
   */

  async componentDidMount() {}

  /**
   * Render
   */

  render() {

    return (
      <div className='container'>
        <div className='hero'>
          <img className='logo' src={logo} />
          
          Think Do Make
        
          <a href="mailto:tim@thinkdomake.couk" className='contact'>
            tim@thinkdomake.co.uk
            <br />
            07706 708 901
          </a>
        </div>
        
        <div className='tagline'>
          Web development consultancy
        </div>

        <div className="works">
          <Work
            image={bulb}
            title="Bulb Energy"
            style={{
              backgroundSize: '109%',
              backgroundPosition: '0 0',

            }}
          />
          <Work
            image={speedo}
            title="Speedo On"
            style={{
              backgroundSize: '159%',
              backgroundPosition: '-39px -43px',

            }}
          />
          <Work
            image={ea}
            title="Everyone Active"
          />
          <Work
            image={edtech}
            title="Edtech Impact"
          />
        </div>

        

        
      </div>
    )
  }
}
